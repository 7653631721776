import React from "react"

import "./page-header.scss"

export default function PageHeader(props) {
  var style = {
    backgroundImage: "url(" + props.header + ")",
  }

  return (
    <div className="at-pageheader" style={style}>
      <div className="at-container">
        <h1 className="at-pageheader__title">{props.title}</h1>
        <div class="at-pageheader__divider"></div>
      </div>
    </div>
  )
}
