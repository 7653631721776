import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <>
      <SEO
        title={"Développement personnel"}
        keywords={[`développement personnel`]}
      />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader
          title="Développement personnel"
          header="/images/headers/16.jpg"
        />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">
                Accompagnement en développement personnel - De Montréal à chez
                toi
              </h2>
              <div class="at-page__title-divider"></div>
              <p>
                Le développement personnel a pour but de te réaligner avec tes
                valeurs et tes besoins. C’est un travail constant, un
                entraînement sans fin qui demande une pratique régulière,
                accompagné par un coach et qui ne se fait pas en seulement une
                ou deux séances.
              </p>
              <p>
                Prenons l’exemple du yoga. Avant d’être parfaitement autonome
                dans ta pratique, il est important d'apprendre les bases, de
                savoir comment te positionner, t'étirer et bien respirer. La
                meilleure des façons, c'est en s'entraînant avec un professeur
                pendant quelques séances. Une fois autonome dans ta pratique,
                rien ne t'empêche de le recontacter pour revoir les bases
                oubliées ou peaufiner ton apprentissage.
              </p>
              <p>
                Le développement personnel, c’est exactement la même chose !
                C’est un entraînement de l’esprit et je suis le “professeur”, la
                coach de vie.
              </p>
              <p>
                Tout comme tu prends soin de ton corps en faisant du sport, en
                t’offrant des massages, des séances d'acupuncture ou
                d’ostéopathie, le développement personnel permet de détendre ton
                esprit, de muscler tes réflexes mentaux, d’assimiler les bons
                mouvements psychiques et de te libérer de chaînes invisibles.
              </p>
              <p>
                C’est pourquoi, je propose un un accompagnement adapté à ton
                rythme. La première dure une heure et demie et les autres
                environ une heure. Lors de chacune d'entre elles, nous
                aborderons ensemble de nombreux thèmes en fonction de toi et des
                situations que tu vis.
              </p>
              <p>
                Entre les séances, nous pourrons échanger par courriel, messages
                instantanés ou vocaux si tu en éprouves le besoin. Je suis
                joignable en permanence. Nous pouvons nous rencontrer aussi bien
                en présnetiel qu'en ligne par visioconférence. L’avantage c’est
                que je peux ainsi t’aider où que tu habites, que ce soit à
                Montréal, Longueuil, Laval, Québec, au Canada, en France ou
                ailleurs dans le monde !
              </p>
              <p>
                Au travers de nos discussions et de nos échanges, nous allons
                voir ensemble comment t'accomplir et opérer de réels changements
                dans ta vie, à savoir :
              </p>
              <ul>
                <li>Apprendre à rétablir ton estime de soi ;</li>
                <li>
                  Affirmer et poser des limites dans ta vie personnelle et
                  professionnelle ;
                </li>
                <li>Identifier, gérer et exprimer tes émotions ;</li>
                <li>
                  Te faire confiance et apprivoiser tes peurs qui aujourd’hui
                  t’empêchent d’avancer sereinement ;
                </li>
                <li>
                  Faire confiance à ton intuition et la laisser être
                  l’initiatrice des choix de ta vie ;
                </li>
                <li>
                  Identifier tes croyances limitantes afin de les démanteler et
                  pour pouvoir te sentir en phase avec tes aspirations ;
                </li>
                <li>
                  Distinguer tes besoins et tes objectifs dans chacune des
                  sphères de ta vie ;
                </li>
                <li>
                  Muscler ton état d’esprit afin d’obtenir un mental solide et
                  puissant ;
                </li>
                <li>
                  Découvrir le bonheur d’être tout simplement toi : authentique,
                  à ta place sans gêner quiconque ;
                </li>
                <li>
                  Te respecter au travers des évènements que tu vas traverser,
                  des relations que tu entretiens ;
                </li>
                <li>
                  Prendre confiance en toi pour devenir inspirant.e et inspirer
                  les autres avec tes avancées et prouesses ;{" "}
                </li>
                <li>
                  Apprivoiser le pouvoir de la manifestation en attirant toutes
                  ces choses que tu rêves depuis longtemps.
                </li>
              </ul>
              <p>
                Je t'offre la liberté de pouvoir suivre cet accompagnement en
                présentiel, à la clinique{" "}
                <a href="https://www.chezlosteo.net/" target="_blank">
                  Chez L'Ostéo Rosemont
                </a>{" "}
                à Montréal ou bien en ligne. Ce suivi en coaching peut durer de{" "}
                <strong>6 à 12 mois</strong> afin d'apprendre et mentaliser les
                bases du développement personnel. Il est structuré en séances de
                60 minutes et nous nous rencontrons toutes les{" "}
                <strong>deux à trois semaines</strong>. La première séance de 90
                minutes est d’une valeur de <strong>120$</strong> (tx incluses)
                et les suivantes d'une durée de 60 minutes sont au prix de{" "}
                <strong>90$</strong>. Entre chaque séance des buts et exercices
                sont donnés afin que le travail entamé soit profitable au
                maximum. Tout appel et échange par mail entre les séances sera
                offert à titre gracieux. Prendre note que toute annulation dans
                les 24h précédant le rendez-vous sera entièrement facturé.
              </p>
            </div>
          </article>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
